import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RealEstateDataFooterData, RealEstateDataPage, StatePropertiesSlice } from 'types'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { Link } from 'gatsby'
import { getMessageWhatsapp, makeItemsNav, scrollToElement } from '@helpers/helper.rendering'
import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { useGetJobsQuery } from '@apis/mediacore/jobs'
import useQuerySummary from '@hooks/useQuerySummary'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSendContactFormMutation } from '@apis/originalTokkoApi/contact'
import toast from 'react-hot-toast'
import $ from 'jquery'
import { useSelector } from 'react-redux'
import { getfavorites } from '@helpers/helper.favorites'
import { useCreateFormMutation as useSendContactFormMutationMediacore } from '@apis/mediacore/contact'
interface ExternalProps {
  pages: RealEstateDataPage[]
  currentPage?: RealEstateDataPage[]
  development_id?: string
  property_id?: string
}

const isBrowser = typeof window !== 'undefined'

type CombinedProps = RealEstateDataFooterData & ExternalProps
const Footer1 = ({
  titles,
  main_office,
  pages,
  modern_version,
  image_gatsby,
  global_data,
  development_id,
  property_id,
  advanced_whatsapp,
  buttonCompare,
  buttonHeart,
}: CombinedProps) => {
  const getCurrentYear = () => new Date().getFullYear()

  const tokkoKey = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()
  const development = useQueryDevelopment(development_id, tokkoKey)
  const property = useQueryProperty(property_id, tokkoKey)
  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const logo = global_data.logo
  const college = global_data.college + ' ' + global_data.registration_number
  const captcha = global_data.pageConfig.keys.captcha
  const siteId = global_data.id

  const {
    updaterFavorites, // map,
  }: StatePropertiesSlice = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )

  const [sendContactForm] = useSendContactFormMutation()
  const [sendContactFormMediacore] = useSendContactFormMutationMediacore()

  const [disabled, setDisabled] = useState(true)

  const [closeWhatsapp, setCloseWhatsapp] = useState(true)

  const icons: string = global_data.pageConfig.design.icons
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const realEstateName = global_data.name

  const sendForm = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const tags = ['Sitio Web', 'Newsletter', realEstateName]
    if (API_KEY) {
      sendContactForm({
        API_KEY: API_KEY,
        body: {
          email: datos.email,
          tags,
        },
      })
    }
    sendContactFormMediacore({ data: datos, type: 'newsletter', site: global_data.id })
    ;(document.getElementById('formNewsletter') as HTMLFormElement)?.reset()
    return toast.success('Te suscribiste a nuestro newsletter correctamente.')
  }

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    })
  }

  const [datos, setDatos] = useState({
    email: '',
  })

  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { data: allJobsData } = useGetJobsQuery({ site: siteId })

  const API_KEY = global_data.pageConfig.keys.tokko
  const { allSummaryData } = useQuerySummary({ API_KEY })

  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )

  const branchesWa = advanced_whatsapp || false

  useEffect(() => {
    const update = () => {
      // dispatch(updateFields(datos))
    }
    update()
    if (!captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])

  const [isComponentMounted, setIsComponentMounted] = useState(false)

  useEffect(() => {
    setIsComponentMounted(true)
  }, [])

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))
  const countFavorites = listFavorites.length
  useEffect(() => {
    if (isBrowser) {
      setListFavorites(getfavorites('prop', 'template-site'))
    }
  }, [updaterFavorites])

  const get_whatsApp_element = () => {
    if (isBrowser) {
      if (branchesWa) {
        if (branch_office?.length === 0) {
          return (
            <div>
              <a
                href={
                  'https://web.whatsapp.com/send?text=' +
                  getMessageWhatsapp(development?.data, property?.data, location?.href) +
                  '&phone=+' +
                  branch_office[0]?.contact_data.phonesLine[0].replaceAll('-', '')
                }
                target="_blank"
                className={'icon-whatsapp contact-btn d-none d-lg-flex '}
              ></a>
              <a
                href={
                  'https://api.whatsapp.com/send?text=' +
                  getMessageWhatsapp(development?.data, property?.data, location?.href) +
                  '&phone=+' +
                  branch_office[0]?.contact_data.phonesLine[0].replaceAll('-', '')
                }
                target="_blank"
                className={'icon-whatsapp contact-btn d-flex d-lg-none '}
              ></a>
            </div>
          )
        } else {
          return (
            <>
              <div
                onClick={() => setCloseWhatsapp(!closeWhatsapp)}
                className={
                  'icon-whatsapp contact-btn justify-content-center align-items-center d-lg-flex div-whatsapp ss ' +
                  (closeWhatsapp ? 'close' : 'open')
                }
              >
                <div
                  className={'float-whatsapp ' + (branch_office?.length === 1 ? 'only-one' : '')}
                >
                  {branch_office?.slice(0, 2).map(
                    (element, index) =>
                      element.contact_data.phonesLine.length > 0 && (
                        <Fragment key={index}>
                          <a
                            href={
                              'https://web.whatsapp.com/send?text=' +
                              getMessageWhatsapp(
                                development?.data,
                                property?.data,
                                location?.href,
                              ) +
                              '&phone=+' +
                              element.contact_data.phonesLine[0].replaceAll('-', '')
                            }
                            target="_blank"
                            className={
                              'contact-btn d-none d-lg-flex justify-content-center align-items-center text-center'
                            }
                          >
                            {element.name}
                          </a>
                          <a
                            href={
                              'https://api.whatsapp.com/send?text=' +
                              getMessageWhatsapp(
                                development?.data,
                                property?.data,
                                location?.href,
                              ) +
                              '&phone=+' +
                              element.contact_data.phonesLine[0].replaceAll('-', '')
                            }
                            target="_blank"
                            className={
                              'contact-btn d-flex d-lg-none justify-content-center align-items-center text-center'
                            }
                          >
                            {element.name}
                          </a>
                        </Fragment>
                      ),
                  )}
                </div>
              </div>
            </>
          )
        }
      } else {
        return (
          <>
            <a
              href={
                'https://web.whatsapp.com/send?text=' +
                getMessageWhatsapp(development?.data, property?.data, location?.href) +
                '&phone=+' +
                contact_data?.phoneMobile![0]?.replaceAll('-', '')
              }
              target="_blank"
              className={'icon-whatsapp contact-btn ss d-none d-lg-flex '}
            ></a>
            <a
              href={
                'https://api.whatsapp.com/send?text=' +
                getMessageWhatsapp(development?.data, property?.data, location?.href) +
                '&phone=+' +
                contact_data?.phoneMobile![0]?.replaceAll('-', '')
              }
              target="_blank"
              className={'icon-whatsapp contact-btn ss d-flex d-lg-none '}
            ></a>
          </>
        )
      }
    }
  }

  const scrollTop = () => {
    if (isBrowser) {
      var body = $('html, body')
      body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {})
    }
  }

  return (
    isComponentMounted && (
      <div className={'footer a position-relative' + (modern_version ? ' modern-footer ' : ' ')}>
        <button
          onClick={() => {
            scrollToElement('header')
            scrollTop()
          }}
          className={
            'btn goTop-btn align-items-center justify-content-center ' +
            (pathname.includes('alquiler') ||
            pathname.includes('venta') ||
            pathname.includes('temporario')
              ? 'd-none'
              : 'd-flex')
          }
        >
          <i className="icon-arrow"></i>
        </button>
        <div className="sup-footer">
          <Container fluid>
            <div
              className={
                'fixed-bar position-fixed ' +
                (main_office?.contact_data.phonesMobile.length === 0 && 'd-none') +
                (pathname.includes('alquiler') ||
                pathname.includes('venta') ||
                pathname.includes('temporario')
                  ? ' d-none'
                  : '')
              }
            >
              <div className={'pulse-border'}></div>
              {get_whatsApp_element()}
            </div>
            <Row className="row">
              <Col
                xs={{ span: 12, order: 1 }}
                lg={{ order: 1 }}
                className="mb-lg-5 mb-0 order-lg-1 order-1"
              >
                <Row>
                  <Col
                    lg={4}
                    xs={{ order: 1 }}
                  >
                    <Row className="item-contact mb-lg-0">
                      <Col
                        lg={12}
                        xs={4}
                      >
                        <h5>{titles?.global}</h5>
                      </Col>
                      <Col
                        lg={12}
                        xs={8}
                      >
                        <a
                          target="_blank"
                          className={'hover-footer-link d-block'}
                          href={'mailto:' + contact_data?.email}
                        >
                          {contact_data?.email}
                        </a>
                        {contact_data?.phoneLine?.map(
                          (phone, index) =>
                            phone && (
                              <p key={index}>Teléfono {phone.replace('54911', '54 9 11 ')}</p>
                            ),
                        )}
                        {contact_data?.phoneMobile?.map(
                          (whatsapp, index) =>
                            whatsapp.length > 0 && (
                              <a
                                key={index}
                                className={'hover-footer-link d-block'}
                                target="_blank"
                                href={
                                  'https://api.whatsapp.com/send?' +
                                  'phone=' +
                                  whatsapp.replaceAll('-', '')
                                }
                              >
                                Whatsapp +{whatsapp.replace('54911', '54 9 11 ')}
                              </a>
                            ),
                        )}
                        <a className="d-block">{college}</a>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={4}
                    xxl={3}
                    xs={{ order: 2 }}
                    className="d-none d-lg-block"
                  >
                    {branch_office?.length > 0 && (
                      <Row className="item-contact d-none d-lg-block">
                        <Col
                          lg={12}
                          xs={4}
                        >
                          <h5 className="">
                            {titles?.branch_office} {branch_office[0]?.name}
                          </h5>
                        </Col>
                        <Col
                          xs={8}
                          lg={12}
                        >
                          <p>{branch_office[0]?.address} </p>
                          <p>
                            {branch_office[0]?.city}, {branch_office[0]?.province}
                          </p>
                          {branch_office[0]?.contact_data?.emails?.map((email, index) => (
                            <a
                              key={index}
                              target="_blank"
                              className={'hover-footer-link '}
                              href={'mailto:' + email}
                            >
                              {email}
                            </a>
                          ))}
                          {branch_office[0]?.contact_data?.phonesLine?.map((phone, index) => (
                            <p key={index}>Teléfono {phone.replace('54911', '54 9 11 ')}</p>
                          ))}
                          {branch_office[0]?.contact_data?.phonesMobile?.map(
                            (whatsapp, index) =>
                              whatsapp.length > 0 && (
                                <a
                                  key={index}
                                  className={'hover-footer-link'}
                                  target="_blank"
                                  href={
                                    'https://api.whatsapp.com/send?' +
                                    'phone=' +
                                    whatsapp.replaceAll('-', '')
                                  }
                                >
                                  Whatsapp + {whatsapp.replace('54911', '54 9 11 ')}
                                </a>
                              ),
                          )}
                          <p className={branch_office[0]?.college === '' ? 'd-none' : ''}>
                            Matrícula {branch_office[0]?.college}
                          </p>
                          <p className={branch_office[0]?.opening_hours === '' ? 'd-none' : ''}>
                            {branch_office[0]?.opening_hours}
                          </p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col
                    lg={4}
                    xxl={{ offset: 1 }}
                    xs={{ order: 3 }}
                    className="pl-lg-4 order-3 d-none d-lg-block"
                  >
                    <div className="item-contact row mb0">
                      <Col
                        xs={4}
                        lg={12}
                      >
                        <h5>{titles?.social}</h5>
                      </Col>
                      <Col
                        lg={12}
                        xs={8}
                      >
                        {social?.instagram && (
                          <a
                            target="_blank"
                            aria-label="Lea más en la red social? instagram"
                            href={social?.instagram}
                            className={social?.instagram ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon icon-instagram${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.facebook && (
                          <a
                            target="_blank"
                            aria-label="Lea más en la red social? facebook"
                            href={social?.facebook}
                            className={social?.facebook ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon icon-facebook${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.linkedin && (
                          <a
                            target="_blank"
                            aria-label="Lea más en la red social? linkedin"
                            href={social?.linkedin}
                            className={social?.linkedin ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon icon-linkedin${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.youtube && (
                          <a
                            target="_blank"
                            aria-label="Lea más en la red social? youtube"
                            href={social?.youtube}
                            className={social?.youtube ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon icon-youtube${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.twitter && (
                          <a
                            target="_blank"
                            aria-label="Lea más en la red social? twitter"
                            href={social?.twitter}
                            className={social?.twitter ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon icon-twitter${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.tiktok && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social tiktok"
                            href={social?.tiktok}
                            className={social?.tiktok ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-tiktok${iconsStyle}`}></i>
                          </a>
                        )}
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={{ span: 4, order: 2 }}
                xs={{ order: 4 }}
                className=""
              >
                <div
                  className={
                    'item-contact row mb-0 button-separator ' +
                    (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                      ? 'd-block'
                      : ' d-none')
                  }
                >
                  <h5 className="">{titles?.modules}</h5>
                  {INVESTOR_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-investor mb-2 d-block d-lg-inline-block"
                        target={'_blank'}
                        href={INVESTOR_CONFIGURATION.url_login}
                      >
                        {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                        {modern_version && <i className={'icon-investment' + iconsStyle}></i>}
                      </a>
                    </div>
                  )}
                  {OWNER_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-investor mb-2 d-block d-lg-inline-block"
                        target={'_blank'}
                        href={OWNER_CONFIGURATION.url_login}
                      >
                        {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                        {modern_version && <i className={'icon-investor-access' + iconsStyle}></i>}
                      </a>
                    </div>
                  )}
                </div>
              </Col>
              <Col
                lg={{ span: 4, order: 4 }}
                xxl={3}
                xs={{ order: 2 }}
              >
                {branch_office?.length > 0 &&
                  branch_office?.map((branch, index) => (
                    <div
                      key={index}
                      className={'item-contact row ' + (index > 0 ? 'd-block' : 'd-lg-none d-flex')}
                    >
                      <Col
                        xxl={9}
                        xs={4}
                        lg={12}
                      >
                        <h5 className="">
                          {titles?.branch_office + ' '} {branch.name}
                        </h5>
                      </Col>
                      <Col
                        xxl={9}
                        lg={12}
                        xs={8}
                      >
                        <p>{branch.address}</p>
                        <p>
                          {branch.city}, {branch.province}
                        </p>
                        {branch.contact_data.emails.map((email, index) => (
                          <a
                            key={index}
                            target="_blank"
                            className={'hover-footer-link '}
                            href={'mailto:' + email}
                          >
                            {email}
                          </a>
                        ))}
                        {branch.contact_data.phonesLine.map((phone, index) => (
                          <p key={index}>Teléfono {phone.replace('54911', '54 9 11 ')}</p>
                        ))}
                        {branch.contact_data.phonesMobile.map(
                          (whatsapp, index) =>
                            whatsapp.length > 0 && (
                              <a
                                key={index}
                                className={'hover-footer-link'}
                                target="_blank"
                                href={
                                  'https://api.whatsapp.com/send?' +
                                  'phone=' +
                                  whatsapp.replaceAll('-', '')
                                }
                              >
                                Whatsapp + {whatsapp.replace('54911', '54 9 11 ')}
                              </a>
                            ),
                        )}
                        <p className={branch.college === '' ? 'd-none' : ''}>
                          Matrícula {branch.college}
                        </p>
                        <p className={branch?.opening_hours === '' ? 'd-none' : ''}>
                          {branch?.opening_hours}
                        </p>
                      </Col>
                    </div>
                  ))}
              </Col>
              <div className="d-block d-lg-none order-3">
                <Row className="item-contact mb0">
                  <Col
                    xs={4}
                    lg={12}
                  >
                    <h5>{titles?.social}</h5>
                  </Col>
                  <Col
                    xs={8}
                    lg={12}
                  >
                    {social?.instagram && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? instagram"
                        href={social?.instagram}
                        className={social?.instagram ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-instagram${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.facebook && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? facebook"
                        href={social?.facebook}
                        className={social?.facebook ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-facebook${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.linkedin && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? linkedin"
                        href={social?.linkedin}
                        className={social?.linkedin ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-linkedin${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.youtube && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? youtube"
                        href={social?.youtube}
                        className={social?.youtube ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-youtube${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.twitter && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? twitter"
                        href={social?.twitter}
                        className={social?.twitter ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-twitter${iconsStyle}`}></i>
                      </a>
                    )}
                  </Col>
                </Row>
              </div>
              <Col
                lg={{ span: 4, order: 5 }}
                xxl={{ offset: 1 }}
                xs={{ order: 5 }}
              >
                {/* <div className={ "content-contact mt-auto " + (investor.id ? "" : "enabled-border")}> */}
                <div className={'content-contact mt-auto ' + 'enabled-border'}>
                  <h5>{titles?.newsletter}</h5>
                  <form
                    onSubmit={sendForm}
                    id="formNewsletter"
                  >
                    <div className="d-flex mb-lg-3">
                      <input
                        type="email"
                        onChange={handleInputChange}
                        name="email"
                        required
                        placeholder="Ingresá tu mail"
                        className="mr-2"
                      />
                      <button
                        disabled={disabled}
                        type="submit"
                        className={
                          'submit ' +
                          (modern_version ? 'icon-email' + iconsStyle : 'icon-arrow' + iconsStyle)
                        }
                        aria-label="Boton enviar formulario"
                      ></button>
                    </div>
                    {captcha ? (
                      <ReCAPTCHA
                        sitekey={captcha}
                        onChange={() => setDisabled(false)}
                      />
                    ) : (
                      ''
                    )}
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="a">
          <Container fluid>
            <Row>
              <Col
                xs={12}
                lg={2}
                className="d-flex d-lg-block align-items-start flex-column"
              >
                <div className="logo-footer mx-auto mx-lg-0">
                  <Link to="/">
                    {image_gatsby ? (
                      <GatsbyImage
                        className="w-100 logo-footer-mobile"
                        objectFit="cover"
                        alt="hero-img"
                        image={getImage(image_gatsby?.url!)!}
                      />
                    ) : (
                      <img
                        alt="logo-footer-mobile"
                        height={'32'}
                        width={'150'}
                        src={logo}
                      ></img>
                    )}
                  </Link>
                </div>
              </Col>
              <Col
                xs={5}
                lg={10}
                className={
                  'd-lg-flex d-none align-items-center ' +
                  (modern_version ? 'justify-content-end' : 'justify-content-evenly')
                }
              >
                <ul className="d-flex">
                  {makeItemsNav(allSummaryData, allBlogData, pages, allJobsData)
                    .filter((page) => !page.path.includes('detalle'))
                    .map(({ path, title }, index) => (
                      <li
                        className="px-lg-4"
                        key={index}
                      >
                        <Link
                          // onClick={() => staticBody() + setOpen(false)}
                          className={
                            'text-uppercase ' +
                            ((pathname.includes(path) && pathname !== '' && path !== '/') ||
                            (path === '/' && pathname === '/')
                              ? 'active'
                              : '')
                          }
                          to={path}
                        >
                          {title}
                        </Link>
                      </li>
                    ))}
                  {buttonHeart && (
                    <li className={pathname.includes('favoritos') ? 'active' : ''}>
                      {countFavorites === 0 ? (
                        <span className="hover-like  align-items-center">
                          <span
                            onClick={() =>
                              toast.error(
                                'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                              )
                            }
                            className={'hover-like disabled align-items-center me-lg-2 '}
                          >
                            {buttonCompare?.value}
                          </span>
                          <i
                            onClick={() =>
                              toast.error('No tienes propiedades favoritas seleccionadas.')
                            }
                            className={
                              `me-4 ms-2 icon-favoritos-heart${iconsStyle} ` +
                              (countFavorites < 1 && ' disabled ')
                            }
                          ></i>
                        </span>
                      ) : (
                        <div className={'hover-like cursor-pointer  align-items-center '}>
                          <span
                            id="count_favorites"
                            className={'ms-lg-3 d-flex align-items-center ' + ' animate__animated '}
                          >
                            {countFavorites === 1
                              ? buttonCompare?.link && (
                                  <span
                                    onClick={() =>
                                      toast.error(
                                        'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                                      )
                                    }
                                    className={
                                      'hover-like me-lg-3 me-2 disabled align-items-center '
                                    }
                                  >
                                    {buttonCompare?.value}
                                  </span>
                                )
                              : buttonCompare?.link && (
                                  <Link
                                    to={buttonCompare?.link!}
                                    className={'hover-like me-lg-3 me-2 align-items-center '}
                                  >
                                    {buttonCompare?.value}
                                  </Link>
                                )}
                            <Link to={buttonHeart?.link!}>
                              {countFavorites}
                              <i className={`ms-2 icon-favoritos-heart${iconsStyle} `}></i>
                            </Link>
                          </span>
                        </div>
                      )}
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
          <div className="copy-bg d-flex align-items-start d-lg-none text-center justify-content-center mt-5">
            <p className="copy">
              Promoted by{' '}
              <a
                target="_blank"
                style={{ color: '#00A1FF' }}
                href="https://mediahaus.com.ar"
              >
                {' '}
                MediaHaus
              </a>{' '}
              - Powered by{' '}
              <a
                target="_blank"
                href="https://mediacore.app/"
              >
                {' '}
                MediaCore
              </a>
              <br />
              <span
                className="white"
                style={{ fontSize: '0.7rem' }}
              >
                Todos los derechos reservados ® {getCurrentYear()}
              </span>
            </p>
          </div>
          <Row className="copy-bg py-lg-5 d-none d-lg-flex justify-content-between align-items-end">
            <Col
              xs={12}
              className="text-center justify-content-center d-lg-flex align-items-start"
            >
              <p className="copy">
                Promoted by{' '}
                <a
                  target="_blank"
                  style={{ color: '#00A1FF' }}
                  className="normal-color"
                  href="https://mediahaus.com.ar"
                >
                  {' '}
                  MediaHaus
                </a>{' '}
                - Powered by{' '}
                <a
                  target="_blank"
                  href="https://mediacore.app/"
                >
                  {' '}
                  MediaCore
                </a>
                <br />
                <span className="small-text white">
                  Todos los derechos reservados ® {getCurrentYear()}
                </span>
              </p>
            </Col>
          </Row>
        </footer>
      </div>
    )
    // <Navbar bg="dark">
    //   <Container className="justify-content-center">
    //     <Navbar.Brand className="text-white">
    //       Esto esta hardcodeado en el component footer 1 | {title}
    //     </Navbar.Brand>
    //   </Container>
    // </Navbar>
  )
}

export default Footer1
