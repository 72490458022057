import { useGetDevelopmentDetailQuery as useGetDevelopmentsTokkoQuery } from '@apis/middlewareTokkoApi/developments'
import { useGetDevelopmentQuery as useGetDevelopmentsMediacoreQuery } from '@apis/mediacore/emprendimientos'

function useQueryDevelopment(id?: string, tokkoKey?: string) {
  const skip = !id

  const { data, isLoading, isError, isFetching } = tokkoKey
    ? useGetDevelopmentsTokkoQuery({ API_KEY: tokkoKey, DEVELOPMENT_ID: id }, { skip })
    : useGetDevelopmentsMediacoreQuery(
        {
          CLIENT_ID: process.env.GATSBY_CLIENTID,
          DEVELOPMENT_ID: id,
        },
        { skip },
      )

  return { data, isLoading, isError, isFetching }
}

export default useQueryDevelopment
