import { useSendContactFormMutation as useSendContactFormMutationTokko } from '@apis/originalTokkoApi/contact'
import { useCreateFormMutation as useSendContactFormMutationMediacore } from '@apis/mediacore/contact'
import { makeJsonContact } from '@helpers/helper.contact'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import toast, { Toaster } from 'react-hot-toast'
import { Development, Property, RealEstateDataPageComponentData } from 'types'

interface PropsType {
  property: Property
  development: Development
  from: string
  smallPadding?: boolean
  italic: boolean
  focusSecondary?: boolean
}

type CombinedProps = RealEstateDataPageComponentData & PropsType

const Form_1 = (props: CombinedProps) => {
  //   const [text, setText] = useState('')
  const [disabled, setDisabled] = useState(true)
  //   const { data } = props
  // const { from = '' } = props
  const {
    property,
    development,
    underlineMode,
    button,
    global_data,
    italic = false,
    smallPadding = false,
    is_short = false,
    focusSecondary = false,
  } = props

  const API_KEY = global_data.pageConfig.keys.tokko
  const captcha = global_data.pageConfig.keys.captcha
  const divisionPath = global_data.divisionPath
  const realEstateName = global_data.name
  const [sendContactFormTokko] = useSendContactFormMutationTokko()
  const [sendContactFormMediacore] = useSendContactFormMutationMediacore()
  const [datos, setDatos] = useState({
    name: '',
    cellphone: '',
    email: '',
    text: '',
  })

  const handleInputChange = (event: any) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    })
  }

  const sendForm = (event: any) => {
    event.preventDefault()
    var tags = [...getUtm(), 'Sitio Web', realEstateName]
    if (process.env.GATSBY_IS_SITE_COMPILER === 'true') {
      tags.push(divisionPath!.replaceAll('/', ''))
    }
    if (!development?.id && !property?.id) {
      tags.push('Contacto')
    }

    if (property?.id) {
      tags.push('Propiedad')
    }

    if (development?.id) {
      tags.push(development.name)
    }

    const { name, cellphone, email, text } = datos

    const body = makeJsonContact({
      name,
      cellphone,
      email,
      text,
      tags,
      property,
      development,
    })

    const type = development ? 'development' : property ? 'property' : 'contact'

    if (API_KEY) {
      sendContactFormTokko({ API_KEY: API_KEY, body: body })
      sendContactFormMediacore({
        data: {
          ...datos,
          tags: tags,
          property: property,
          development: development,
          url: location.href,
        },
        type: type,
        site: global_data.id,
      })
    } else {
      sendContactFormMediacore({
        data: {
          ...datos,
          tags: tags,
          property: property,
          development: development,
          url: location.href,
        },
        type: type,
        site: global_data.id,
      })
    }
    ;(document?.getElementById('formContact') as HTMLFormElement).reset()
    return toast.success('¡Mensaje enviado correctamente!')
  }

  useEffect(() => {
    if (!captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])

  const getUtm = () => {
    if (typeof window !== 'undefined') {
      var queryString = window.location.search
      var urlParams = new URLSearchParams(queryString)
      const tags = []
      for (const [key, value] of urlParams) {
        if (key.includes('source')) {
          tags.push('Origen: ' + value)
        }
        if (key.includes('medium')) {
          tags.push('Pieza: ' + value)
        }
        if (key.includes('campaign')) {
          tags.push('Campaña: ' + value)
        }
      }
      return tags
    }
    return []
  }

  return (
    <form
      onSubmit={sendForm}
      id="formContact"
      className={
        'row content-form ajax-form ' +
        (underlineMode ? 'undeline_mode' : '') +
        (smallPadding ? ' small_padding ' : '') +
        (focusSecondary ? ' focus-secondary ' : '')
      }
    >
      <div className={`col-lg-${is_short ? '12' : '6'} mb-lg-4`}>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          placeholder="Nombre y Apellido"
        />
      </div>
      {!is_short && (
        <div className="col-lg-6 mb-lg-4">
          <input
            type="text"
            name="cellphone"
            onChange={handleInputChange}
            placeholder="Teléfono"
          />
        </div>
      )}
      <div className="col-lg-12 mb-lg-4 pr-lg-3">
        <input
          type="email"
          name="email"
          onChange={handleInputChange}
          placeholder="Mail*"
          required
        />
      </div>
      <div className="col-lg-12 mb-lg-4">
        <textarea
          name="text"
          cols={30}
          rows={5}
          onChange={handleInputChange}
          placeholder="Mensaje"
        ></textarea>
      </div>
      <div className="col-lg-12 d-lg-flex justify-content-between mb-5">
        <p className="d-block d-lg-none">*Campo obligatorio.</p>
        <div className="">
          {captcha ? (
            <ReCAPTCHA
              sitekey={captcha}
              onChange={() => setDisabled(false)}
            />
          ) : (
            ''
          )}
          <input
            type="submit"
            disabled={disabled}
            value={button?.value || 'Enviar'}
            className={
              'btn btn-primary submit mt-4 w-100 ' +
              (button?.border ? ' border ' : '' + (italic ? ' fst-italic' : ''))
            }
            id="submit-info"
          />
        </div>
        <p className="d-none d-lg-block">*Campo obligatorio.</p>
      </div>
    </form>
  )
}
export default Form_1
